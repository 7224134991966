import React, { useEffect, useState } from 'react';
import { List, ListItem, Button, Paper, Box, ListSubheader } from '@mui/material';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import './slidinglist.css'; // Ensure you import your CSS for animations

export interface MessageListItem {
  msgId: string
  socketId: string,
  serviceId: string
  card: any
  group: number
}

export interface SlidingListProps {
  items: MessageListItem[]
}


function SlidingList(props: SlidingListProps) {
  const [items, setItems] = useState<MessageListItem[]>([]);
  useEffect(() => {
    setItems(props.items)
  }, [props]);

  return (
    <List sx={{width:'100%', boxShadow: 0}}>
      <TransitionGroup>
        {items.map((item, index) => (
          <CSSTransition key={index} timeout={500} classNames="item">
            {/*(item.group === 0) && (
              <ListSubheader sx={{ bgcolor: 'background.paper' }}>
                Dit is uw eerste keer
              </ListSubheader>
            )*/}
            <ListItem sx={{width:'100%',boxShadow: 0}}>{item.card}</ListItem>
          </CSSTransition>
        ))}
      </TransitionGroup>
    </List>

  );
}

export default SlidingList;

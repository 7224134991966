import * as React from 'react';
import { styled, ThemeProvider } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Fab from '@mui/material/Fab';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Avatar from '@mui/material/Avatar';
import { RoomServiceTwoTone } from '@mui/icons-material';
import { BottomNav } from '../../BottomNav/BottomNav';
import { Navbar } from '../../Navbar/Navbar';
import { ConnectionStatus } from '../../models';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import theme, { ThemeColors } from '../../themes/mui'
import SlidingList from '../../SlidingList/sliding-list';

export interface HomeProps {
    home: ConnectionStatus,
    version: string,
    lng: string,
    displayName: string,
    error: string,
    isConnected: boolean,
    messages: any[]
}

const StyledFab = styled(Fab)({
    position: 'absolute',
    zIndex: 1,
    top: -40,
    left: 0,
    right: 0,
    margin: '0 auto'
});

export default function MainPage(props: HomeProps) {
    const { t } = useTranslation();
    const [home, setHome] = useState<ConnectionStatus>();
    const [version, setVersion] = useState<string>();
    const [lng, setLng] = useState<string>();
    const [error, setError] = useState<string>();
    const [isConnected, setIsConnected] = useState<boolean>(false);
    const [messages, setMessages] = useState<any[]>([])

    useEffect(() => {
        console.log(`[HOME] :: props changed`, props, home, version, lng)
        let changed = false;
        if (props.home != home) {
            setHome(props.home)
            changed = true
        }
        if (props.version != version) {
            setVersion(props.version)
            changed = true
        }
        if (props.lng != lng) {
            setLng(props.lng)
            changed = true
        }
        if (props.error != error) {
            setError(props.error)
            changed = true
        }
        setMessages(props.messages)

        if (changed) {
            console.log(`[Home] :: updating navbar`)
            //toast.update(navBarId.current, { containerId:"Nav", render: <Navbar error={props.error} home={home} version={`${version} - ${lng} ${props.displayName?`- ${props.displayName}`:""}`} /> })
            console.log(`[HOME] :: Updated navbar`)
        }
    }, [props])
    return (
        <ThemeProvider theme={theme}>
            <React.Fragment>
                <Box display={'flex'} flexDirection={'column'} sx={{ height:'0px', backgroundColor:'black', pb: '50px', boxShadow: 0 }}>

                    <AppBar elevation={0} position="fixed" sx={{ backgroundColor: ThemeColors.primary, height: '50px', boxShadow: 0 }}>
                        <Toolbar sx={{height: '50px' }} >
                            <Navbar error={props.error} home={home} version={`${version} - ${lng} ${props.displayName ? `- ${props.displayName}` : ""}`} />
                        </Toolbar>
                    </AppBar>

                    {(isConnected || messages.length>0) ? <Box sx={{boxShadow: 0}} marginTop={'30px'}>
                        <SlidingList items={messages}/>
                        {/*<List sx={{ mb: 2 }}  >
                            {messages.map(({ id, item }, idx) => (
                                <React.Fragment key={idx}>
                                    {(id === 1) && (
                                        <ListSubheader sx={{ bgcolor: 'background.paper' }}>
                                            Today
                                        </ListSubheader>
                                    )}
                                    {item}
                                </React.Fragment>
                            ))}
                        </List>*/}
                    </Box> :
                        <></>}
                </Box>
                <AppBar position="fixed" sx={{ width: '100%', height: '30px', top: 'auto', bottom: 0, backgroundColor: ThemeColors.primary }}>
                    <Toolbar>
                        <BottomNav />
                        <StyledFab color="secondary" aria-label="add">
                            <RoomServiceTwoTone />
                        </StyledFab>
                    </Toolbar>
                </AppBar>
            </React.Fragment>
        </ThemeProvider>
    );
}
/*
    {(id === 1) && (
        <ListSubheader sx={{ bgcolor: 'background.paper' }}>
            Today
        </ListSubheader>
    )}
    {(id === 5) && (
        <ListSubheader sx={{ bgcolor: 'background.paper' }}>
            Yesterday
        </ListSubheader>
    )}
    <ListItemButton>
        <ListItemAvatar>
            <Avatar alt="Profile Picture" src={person} />
        </ListItemAvatar>
        <ListItemText primary={primary} secondary={secondary} />
    </ListItemButton>
*/
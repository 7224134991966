import { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import styles from './Navbar.module.css'
import { HomeTwoTone } from '@mui/icons-material'
import { ThemeColors } from '../themes/mui'
import { ConnectionStatus } from '../models'

export interface NavBarProps {
    version?: string
    home?: ConnectionStatus
    error?: string
}

export const Navbar = (props: NavBarProps) => {
    //console.log(`[NAVBAR] :: load `, props);
    const [home, setHome] = useState<ConnectionStatus>();
    const [version, setVersion] = useState<string>();
    const [error, setError] = useState<string>();

    useEffect(() => {
        //console.log(`[NAVBAR] :: props changed `, props);
        setHome(props.home)
        setVersion(props.version)
        setError(props.error)
    }, [props])

    return (
        <Box display={'flex'} sx={{marginTop :'3px', marginLeft:'5px', xs: 'flex', md: 'flex'  }} flexDirection={'column'}>
            <Typography sx={{
                mr: 2,
                //display: {xs: 'none', md: 'flex'},
                fontFamily: 'monospace',
                fontWeight: 700,
                fontSize: '8px',
                //letterSpacing: '.3rem',
                color: ThemeColors.secondary,
                textDecoration: 'none',
            }} >v{version}</Typography>
            <Box display={'flex'} flexDirection={'row'}>
                <HomeTwoTone
                sx={{
                    animation: `${error?"blinking 1.5s infinite ease-in-out":"none"}`,
                '@keyframes blinking': {
                    '0%': { opacity: 1 },
                    '50%': { opacity: 0 },
                    '100%': { opacity: 1 },
                },
                }}
                 color={home as any} />
                <Typography sx={{
                    ml: 2,
                    //display: {xs: 'none', md: 'flex'},
                    fontFamily: 'monospace',
                    fontWeight: 700,
                    fontSize: '8px',
                    //letterSpacing: '.3rem',
                    color: ThemeColors.error,
                    textDecoration: 'none',
                }} >{error}</Typography>
            </Box>
        </Box>
    )
}

/*
const Navbar = (props: NavBarProps) => {

    return (
        <AppBar position="static" color="primary" className={styles.Navbar}>
            <Container maxWidth="xl">
                <Toolbar disableGutters sx={{ justifyContent: 'space-between' }}>
                    <Box
                        className={styles.FlexCentered}
                    >
                        <Box sx={{ display: { xs: 'flex', md: 'flex' }, }} flexDirection={'column'}>
                            <Typography sx={{
                                mr: 2,
                                //display: {xs: 'none', md: 'flex'},
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                fontSize: '8px',
                                //letterSpacing: '.3rem',
                                color: 'inherit',
                                textDecoration: 'none',
                            }} >v{props.version}</Typography>
                            <HomeTwoTone color={props.home ? 'success' : 'error'} />
                        </Box>
                        <Typography
                            variant="h6"
                            noWrap
                            sx={{
                                mr: 2,
                                display: { xs: 'none', md: 'flex' },
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                letterSpacing: '.3rem',
                                color: 'inherit',
                                textDecoration: 'none',
                            }}
                        >
                            WEBO HOMEHUB
                        </Typography>
                    </Box>
                    <Typography
                        variant="h5"
                        noWrap
                        sx={{
                            mr: 2,
                            display: { xs: 'flex', md: 'none' },
                            flexGrow: 1,
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        WEBO HOMEHUB
                    </Typography>
                </Toolbar>
            </Container>
        </AppBar>
    )
}*/



export class AuthUserData {
    uuid: string
    loginEmail: string
    fName: string
    lName: string
    profile: 'admin'|'user'|'guest'|'none' = 'none'
}
export class MessageUserData {
    msgId: string
    payload: object
    payloadData: object
    serviceId: string
    group: number
    groupLabel: {en: string, nl?: string, fr?: string}
}
export class AuthData {
    type: 'REQUEST' | 'RESPONSE' | 'INVALID'
    userData: AuthUserData
}
export class MessageData {
    type: 'ADD' | 'REMOVE' | 'UPDATE'
    userData: MessageUserData
    response?: any
}
export class EmitData {
    socketId: string
    channel: string
    emitData: AuthUserData|MessageUserData
}
export enum ConnectionStatus {ADMIN='info', USER='success', GUEST='warning', OFF='error', ON='white'}
import React, { useEffect, useState } from 'react';
import { List, ListItem, Button, Paper, Box, ListSubheader, Chip, Typography } from '@mui/material';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import './slidinglist.css'; // Ensure you import your CSS for animations
import theme, { ThemeColors } from '../themes/mui';
import { useTranslation } from 'react-i18next';

export interface MessageListItem {
  msgId: string
  socketId: string,
  serviceId: string
  card: any
  group: number
  groupLabel: {en: string, nl?: string, fr?: string}
}

export interface SlidingListProps {
  items: MessageListItem[]
}

export interface ListGroupProps {
  group: number
  label: string
}

export const ListGroup = (props: ListGroupProps) => {
  //console.log(`[SlidingList] :: ListGroup`, props)
  return (
    <ListSubheader sx={{marginBottom:'10px'}} component="div" id={`subheader-${props.group}`}>
      <Typography gutterBottom={true} sx={{backgroundColor:ThemeColors.secondary, }} variant="h6" > <div style={{color:ThemeColors.primary, marginLeft:'5px'}}>{props.label}</div></Typography>
    </ListSubheader>
  )
}


function SlidingList(props: SlidingListProps) {
  const [items, setItems] = useState<MessageListItem[]>([]);
  const [cards, setCards] = useState<any[]>([]);
  const [show, setShow] = useState<boolean>(true)
  const [groups, setGroups] = useState<number[]>([])
  const { t, i18n } = useTranslation();
  
  useEffect(() => {
    setItems(props.items)
    let s = new Set(props.items.map(i => i.group));
    setGroups([...s])
    //console.log(`[SlidingList] :: props changed`, props.items, groups)
    //setCards(props.items.length > 0 ? props.items.map(i => i.card) : [])
    //setShow(false)
    //setTimeout(()=>setShow(true),100)
  }, [props]);

  return (
    <List sx={{ marginBottom: '0px', width: '100%', boxShadow: 0 }}
    subheader={<></>}
    >
      <TransitionGroup>
        {groups.sort((a, b) => a - b).map((gItem, gIdx) => (
          <CSSTransition key={gIdx + 100} timeout={500} classNames="item">
            <div>
            <ListGroup group={gItem} label={`${items.find(i=>i.group==gItem).groupLabel[i18n.resolvedLanguage]}`} />
            {items.sort((a, b) => a.group - b.group).filter(i => i.group == gItem).map((item, index) => (
                <ListItem key={index} sx={{ marginTop: '-25px', width: '100%', boxShadow: 0 }}>{item.card}</ListItem>
            ))}
            </div>
          </CSSTransition>
        ))}
      </TransitionGroup>
    </List>

  );
}

export default SlidingList;
